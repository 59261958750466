// Remove the following lines, if you do not need any of Bootstrap's JavaScript features
import Popper from "popper.js";
import { forEach } from "lodash";
window.jQuery = $;
window.$ = $;
const $ = require('jquery');
require('jquery-rss');
require("bootstrap");
require("./style");

// Remove this demo code, that's only here to show how the .env file works!
if (process.env["HELLO"]) {
  //console.log(`Hello ${process.env.HELLO}!`);
}

$(function(){
  $(".other__item__title").on("click", function() {
    $(this).next().slideToggle();
    console.log($(this).children('div').toggleClass('hidden'))
  });
});

function fetchNews() {


  $.getJSON("https://api.rss2json.com/v1/api.json?rss_url=" + "https://note.com/matsuepiano/m/m627a496a5b20/rss")
    .then(data => {
      console.debug(data);
      const items = data.items;
      const newsItems = $("#news").html = items.slice(0,4).map((item, index) => {
        const d = item.pubDate.split(' ')[0].split('-')
        const date = `${d[0]}年${d[1]}月${d[2]}日`
        const thumbnail = item.thumbnail || '/dist/images/image-empty.png'
        return newsContainer(item.title, date, thumbnail, item.link, index);
      })
      $("#news").html(newsItems);
    });
}

const newsContainer = (title, date, imgUrl, pageUrl, index) => {
  return `
    <a href="${pageUrl}" ${index > 1 && 'class="news-item-pc"'}>
      <div class="news-item">
        <div class="news-thumbnail">
          <img src="${imgUrl || 'dist/images/features/students.png'}"></img>
        </div>
        <div>
          <div>${title}</div>
          <div>${date}</div>
        </div>
      </div>
    </a>
  `
}
const eventsContainer = (title, date, imgUrl, pageUrl, index) => {
  console.debug(index)

  return `
    <a href="${pageUrl}" ${index > 1 && 'class="events-item-pc"'}>
      <div class="events-item">
        <div class="events-thumbnail">
          <img src="${imgUrl}"></img>
        </div>
        <div>
          <div>${title}</div>
          <div>${date}</div>
        </div>
      </div>
    </a>
  `
}

const fetch = () => {
  $.getJSON("https://3gddotrn93.execute-api.ap-northeast-1.amazonaws.com/matsue-magazine")
    .then(data => {
      const events = data.events;
      const eventsItems = events.slice(0,4).map((item, index) => {
        const d = item.publish_at.split(' ')[0].split('/')
        const date = `${d[0]}年${d[1]}月${d[2]}日`
        const thumbnail = item.eyecatch || '/dist/images/image-empty.png'
        const link = `https://note.com/matsuepiano/n/${item.key}`
        return eventsContainer(item.name, date, thumbnail, link, index);
      })
      console.debug(eventsItems);
      $("#events").html(eventsItems);

      const news = data.news;
      const newsItems = news.slice(0,4).map((item, index) => {
        const d = item.publish_at.split(' ')[0].split('/')
        const date = `${d[0]}年${d[1]}月${d[2]}日`
        const thumbnail = item.eyecatch || '/dist/images/image-empty.png'
        const link = `https://note.com/matsuepiano/n/${item.key}`
        return newsContainer(item.name, date, thumbnail, link, index);
      })
      $("#news").html(newsItems);
    }).catch(e => {
      console.error(e)
    });
}
fetch();